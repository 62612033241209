import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { useEffect } from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import { Outlet } from "@remix-run/react";

import {
  GlobalContextProvider,
  useGlobalContext,
  useGlobalContextInit,
} from "~/context/global";
import { MemberContextProvider, useMemberContextInit } from "~/context/member";
import { ToastContextProvider, useToastContextInit } from "~/context/toast";
import { useLocaleNavigate, useTranslation } from "~/libs/i18n";

const Inner = () => {
  const t = useTranslation();
  const localeNavigate = useLocaleNavigate();

  const { setting } = useGlobalContext();
  const memberContextValue = useMemberContextInit();
  const toastContextValue = useToastContextInit();
  const { me, memberModalDisclosure } = memberContextValue;

  useEffect(() => {
    me();
  }, [me]);

  useEffect(() => {
    const user = memberContextValue.user;

    if (user?.id) FirebaseAnalytics.setUserId({ userId: user.id });
    if (user?.member?.region)
      FirebaseAnalytics.setUserProperty({
        name: "region",
        value: user.member.region,
      });
    if (user?.member?.gender)
      FirebaseAnalytics.setUserProperty({
        name: "gender",
        value: user.member.gender,
      });
    if (user?.member?.language)
      FirebaseAnalytics.setUserProperty({
        name: "language",
        value: user.member.language,
      });
    if (
      !!user?.member?.categories &&
      !!setting?.criteria?.category &&
      setting?.criteria?.category?.length > 0
    )
      setting?.criteria?.category.forEach((category) => {
        FirebaseAnalytics.setUserProperty({
          name: `category_${category.name}`,
          value: user?.member?.categories?.find(
            (userCategory) => userCategory.id === category.id
          )
            ? "true"
            : "false",
        });
      });
  }, [memberContextValue.user, setting?.criteria?.category]);

  return (
    <MemberContextProvider value={memberContextValue}>
      <ToastContextProvider value={toastContextValue}>
        <Outlet />
      </ToastContextProvider>

      <Modal
        isCentered
        isOpen={memberModalDisclosure.isOpen}
        onClose={memberModalDisclosure.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Text fontWeight={500} noOfLines={2} p={4}>
              {t("pleaseLogin")}
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="none"
              mr={3}
              onClick={memberModalDisclosure.onClose}
            >
              <Text color="brand.timable-yellow">{t("member.cancel")}</Text>
            </Button>
            <Button
              onClick={() => {
                localeNavigate(`/user/login`);
                memberModalDisclosure.onClose();
              }}
              aria-label="login"
              size="sm"
              px={4}
            >
              {t("member.confirm")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </MemberContextProvider>
  );
};

export default () => {
  const globalContextValue = useGlobalContextInit({});
  return (
    <GlobalContextProvider value={globalContextValue}>
      <Inner />
    </GlobalContextProvider>
  );
};
